// src/contexts/ProfileContext.js
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import apiService, { getToken } from "../services/apiService";
const partnertoken = getToken();
export const ProfileContext = createContext();
export const useProfile = () => useContext(ProfileContext);
export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [Isprofileloading, setIsprofileloading] = useState(false);
  const [profileError, setProfileError] = useState(null);

  const [isupdated, setIsUpdated] = useState(false);
  const [idstatus, setIdstatus] = useState(null);
  const [Isidstatusloading, setIsidstatusloading] = useState(false);
  const [idStatusError, setIdStatusError] = useState(null);

  // States for Matches
  // const [matches, setMatches] = useState(null);
  // const [isMatchesLoading, setIsMatchesLoading] = useState(false);
  // const [MatchesError, setMatchesError] = useState(null);

  // States for Notifications
  const [Notifications, setNotifications] = useState(null);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
  const [NotificationsError, setNotificationsError] = useState(null);
  const [isUserInteracted, setIsUserInteracted] = useState(false); // Track user interaction

  const playSound = () => {
    const sound = new Audio("../assets/sound/sound6.mp3"); // Replace with your sound file path
    sound.play();
  };
  //profile fnuctions
  const fetchProfile = useCallback(async () => {
    // if (!partnertoken) return; // Exit early if there's no partnertoken
    setIsprofileloading(true);
    setProfileError(null);

    try {
      const response = await apiService.getMyProfileById();
      const data = response.data;
      setProfile({
        profile_id: data.profile_id,
        partner_id: data.partner_id,
        address_1: data.address_1 || "",
        address_2: data.address_2 || "",
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        fullName: `${data.first_name || ""} ${data.last_name || ""}`.trim(),
        bio: data.bio || "",
        email: data.email || "",
        phone: data.phone || "",
        gender: data.gender
          ? data.gender.charAt(0).toUpperCase() + data.gender.slice(1)
          : "",
        address: data.address_1
          ? `${data.address_1}${data.address_2 ? `, ${data.address_2}` : ""}`
          : "N/A",
        date_of_birth: data.date_of_birth || "",
        city_id: data.city_id || null,
        experience_files: data.experienceFileUrls || [],
        profile_picture:
          data.profilePictureUrls && data.profilePictureUrls.length > 0
            ? data.profilePictureUrls[0]
            : null,
        experience_level: data.experience_level || "", // New field
        achievements: data.achievements || "", // New field
        preferred_position:
          data.preferred_position && JSON.parse(data.preferred_position).key
            ? JSON.parse(data.preferred_position).key
            : "", // New field
        height: data.height || "", // New field
        weight: data.weight || "", // New field
        role: data.role || "", // New field
        role_ar: data.role_ar || "", // New field
        experience_filesobj: data.experience_files || "[]", // Updated to match new data key
      });
    } catch (error) {
      console.error("Error fetching profile data:", error);
      setProfileError(error.message || "Failed to fetch Profile");
    } finally {
      setIsprofileloading(false);
    }
  }, []);
  const updateProfile = async (updatedProfile) => {
    setIsUpdated(true);
    try {
      await apiService.updateProfile(updatedProfile, profile.profile_id);
      fetchProfile();
      //   setProfile(response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setIsUpdated(false);
    }
  };

  //ID function
  const fetchStatusid = useCallback(async () => {
    // if (!partnertoken) return; // Exit early if there's no partnertoken

    setIsidstatusloading(true);
    setIdStatusError(null);
    try {
      const response = await apiService.getStatusById();
      setIdstatus(
        response.status === 204
          ? { status: "Missing" }
          : response.status === 200
          ? response.data
          : null
      );
    } catch (error) {
      setIdStatusError(error);
      console.error("Error fetching Status ID data:", error);
    } finally {
      setIsidstatusloading(false);
    }
  }, []);

  // // Fetch Matches
  // const fetchMatches = useCallback(async () => {

  //   if (!partnertoken) return;

  //   setIsMatchesLoading(true);
  //   setMatchesError(null);
  //   try {
  //     const response = await apiService.getMatchesByPlayerId();
  //     setMatches(response.data || []);
  //   } catch (error) {
  //     setMatchesError(error.message || "Failed to fetch Matches");
  //   } finally {
  //     setIsMatchesLoading(false);
  //   }
  // }, []);

  // Fetch Notification
  const fetchNotifications = useCallback(async () => {
    if (!partnertoken) return;

    setIsNotificationsLoading(true);
    setNotificationsError(null);
    try {
      const response = await apiService.notifications(1, 10); // Simulating fetching notifications from an API
      console.log(response, response.data);
      if (response.hasnew)
        document.addEventListener("click", playSound, { once: true });
      document.removeEventListener("click", playSound);

      setNotifications(response || []);
    } catch (error) {
      setNotificationsError(error.message || "Failed to fetch Notification");
    } finally {
      setIsNotificationsLoading(false);
    }
  });
  // Handle user interaction to allow sound playback if there are new notifications
  useEffect(() => {
    const handleUserInteraction = () => {
      setIsUserInteracted(true);
      if (Notifications && Notifications.hasnew) {
        playSound();
      }
    };

    if (!isUserInteracted) {
      document.addEventListener("click", handleUserInteraction, { once: true });
    }

    return () => {
      document.removeEventListener("click", handleUserInteraction);
    };
  }, [isUserInteracted, Notifications]);
  useEffect(() => {
    if (partnertoken) {
      if (!profile && !profileError && !Isprofileloading) {
        fetchProfile();
      }
      if (!idstatus && !idStatusError && !Isidstatusloading) {
        fetchStatusid();
      }

      // if (!matches && !MatchesError && !isMatchesLoading) {
      //   console.log("Fetching matches");
      //   fetchMatches();
      // } else {
      //   console.log(matches, MatchesError, "else");
      // }

      if (!Notifications && !NotificationsError && !isNotificationsLoading) {
        console.log("Fetching Notifications");
        fetchNotifications();
      } else {
        console.log(Notifications, NotificationsError, "else");
      }
    }
  }, [
    fetchProfile,
    fetchStatusid,
    idstatus,
    idStatusError,
    profile,
    profileError,
    Isprofileloading,
    Isidstatusloading,
    Notifications,
    NotificationsError,
    isNotificationsLoading,
    fetchNotifications,
  ]);

  const refreshProfileData = useCallback(() => {
    fetchProfile();
    fetchStatusid();
  }, [fetchProfile, fetchStatusid]);
  const logoutProfile = useCallback(() => {
    setProfile(null);
    setIdstatus(null);
    // setMatches(null);
  }, []);
  return (
    <ProfileContext.Provider
      value={{
        profile,
        fetchProfile,
        updateProfile,
        Isprofileloading,
        isupdated,
        fetchStatusid,
        idstatus,
        Isidstatusloading,
        setIsidstatusloading,
        logoutProfile,
        profileError,
        idStatusError,
        refreshProfileData,
        // matches,
        // isMatchesLoading,
        // MatchesError,
        Notifications,
        NotificationsError,
        isNotificationsLoading,
        fetchNotifications,

        // Expose this function to trigger fetching when needed
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
